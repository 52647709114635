import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Grid } from '@material-ui/core';
import FilterDisplay from './FilterDisplay';
import styles from './TopBarStyle';
import Filters from './Filters';
import Columns from './Columns';
import Addbtn from './Addbtn';
import AddCommonBtn from './AddCommonBtn';
import Createbtn from './Createbtn';
import Searchbtn from './Searchbtn';
import UpdateSyncBtn from './UpdateSyncBtn';
import MobileMenuSelector from './MobileMenuSelector';

class TopBar extends Component {
  triggerSearch = (e) => {
    if (e.charCode === 13) {
      const { onSearch } = this.props;
      onSearch(e);
    }
  }
  triggerSearchClose = () => {
    const { onSearchClose } = this.props;
    onSearchClose();
  }
  render() {
    const { classes, sfc, ...other } = this.props;
    const { location, page } = this.props;
    let showsearch = true;
    let showFilter = true;
    let showplus = true;
    let showupdatebtn = true;
    let createbtn = false;
    let globalbtn = true;
    switch (location.pathname) {
      case '/products-vendor-orders':
        showFilter = false;
        showupdatebtn = false;
        globalbtn = false;
        break;
      case '/top-selling-products':
      case '/products-low-in-stocks':
      case '/products-out-of-stocks':
      case '/pending-orders':
      case '/shipped-orders':
      case '/supply-receipts':
        showplus = false;
        break;
      case '/returned-orders':
      case '/return-authorization':
        createbtn = true;
        break;
      case '/support':
      case '/billing-support':
      case '/tech-support':
      case '/special-projects':
        showsearch = false;
        break;
      default:
        break;
    }
    if (location.pathname.indexOf('/support/') !== -1) {
      showsearch = false;
    }
    return (
      <AppBar position="fixed" className={`${page} ${classes.appBar} ${page}`}>
        <Toolbar className={`toolbar1 ${classes.toolbarHeight} ${classes.toolbar} ${classes.toolbarmobile}`}>
          <Grid className={`${classes.barcontainer} ${classes.barmobile}`} container>
            <Grid className={`topleftGrid ${classes.leftcontainer}`} item xs={7} sm={7}>
              {showupdatebtn ?
                <UpdateSyncBtn displayfor="mobile" {...other} />
              : null }
            </Grid>
            <Grid className={`toprightGrid ${classes.filterColumns}`} item xs={5} sm={5}>
              <MobileMenuSelector {...other} />
            </Grid>
          </Grid>
        </Toolbar>
        <Toolbar className={`toolbar2 ${classes.toolbarHeight} ${classes.toolbar}`}>
          <Grid className={`${classes.barcontainer} ${classes.bardesktop}`} container>
            <Grid className={`leftGrid ${classes.leftcontainer}`} item xs={12} sm={4}>
              { showplus ?
                <div className={classes.add}>
                  { !createbtn ?
                    <>
                      { globalbtn ?
                        <AddCommonBtn {...other} />
                      :
                        <Addbtn {...other} />
                      }
                    </>
                  : null }
                  { createbtn ?
                    <Createbtn searchType="infilter" {...other} />
                  : null }
                </div>
              : null }
              { showsearch ?
                <div className={classes.search}>
                  <Searchbtn
                    {...other}
                    triggerSearch={e => this.triggerSearch(e)}
                    triggerSearchClose={this.triggerSearchClose}
                  />
                </div>
              : null }
            </Grid>
            { sfc ?
              <Grid className={`rightGrid ${classes.filterColumns}`} item xs={12} sm={8}>
                {showupdatebtn ?
                  <UpdateSyncBtn displayfor="desktop" {...other} />
                : null }
                {showFilter ?
                  <Filters {...other} />
                : null }
                <Columns {...other} />
              </Grid>
            : null }
          </Grid>
        </Toolbar>
        <FilterDisplay {...other} />
      </AppBar>
    );
  }
}

TopBar.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  onSearch: PropTypes.func, // eslint-disable-line
  onSearchClose: PropTypes.func, // eslint-disable-line
  page: PropTypes.string, // eslint-disable-line
};

export default withStyles(styles)(TopBar);
