import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography/* , Badge */ } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as SettingsIcon } from 'resources/icons/settings-icon.svg';
import iPSLogo from 'resources/images/iPSLogo.png';
/* import { ReactComponent as Holiday } from 'resources/icons/holiday.svg'; */
import styles from './LeftMenuStyle';

class LeftMenu extends Component {
  close = () => {
    const match = window.matchMedia('(max-width: 1240px)');
    if (match.matches) {
      const { actions } = this.props;
      const modalKey = 'mobile-menu';
      actions.ui.closeModal({ modalKey });
    }
  };

  clearEvent = () => {
    const match = window.matchMedia('(max-width: 1240px)');
    if (match.matches) {
      const { actions } = this.props;
      const modalKey = 'mobile-menu';
      actions.ui.closeModal({ modalKey });
    }
    const { resetCalendarEventsNotif } = this.props;
    resetCalendarEventsNotif();
  };

  /* testFetch = () => {
    const { testApi } = this.props;
    testApi();
  }; */

  render() {
    const {
      classes, menuItems, location, profile, /* eventsnotif, */
    } = this.props;
    const loc = location.pathname.split('/', 4, 0);
    let menu = '';
    switch (loc[1]) {
      case 'pending-orders':
      case 'shipped-orders':
      case 'returned-orders':
      case 'return-authorization':
      case 'third-party':
        menu = 'Sales Orders';
        break;
      case 'products-vendor-orders':
        menu = 'Purchase Orders';
        break;
      case 'products':
      case 'top-selling-products':
      case 'products-low-in-stocks':
      case 'products-out-of-stocks':
        menu = 'Products';
        break;
      case 'stock-alert-settings':
        menu = 'Stock Alerts';
        break;
      case 'support':
      case 'billing-support':
      case 'tech-support':
      case 'special-projects':
        menu = 'Support';
        break;
      case 'sales-by-sku':
      case 'inventory-reports':
      case 'returns-reports':
      case 'supply-receipts-reports':
        menu = 'Reports';
        break;
      case 'notification':
      case 'manage-roles':
        menu = 'Settings';
        break;
      default:
        break;
    }
    for (const key in menuItems) { // eslint-disable-line
      menuItems[key].active = true;
      switch (menuItems[key].label) {
        case 'Sales Orders':
          if (!profile.role.role.SALES_ORDERS) {
            menuItems[key].active = false;
          }
          break;
        case 'Purchase Orders':
          if (!profile.role.role.PURCHASE_ORDERS) {
            menuItems[key].active = false;
          }
          break;
        case 'Supply Receipts':
          if (!profile.role.role.SUPPLY_RECEIPTS) {
            menuItems[key].active = false;
          }
          break;
        case 'Products':
          if (!profile.role.role.PRODUCTS) {
            menuItems[key].active = false;
          }
          break;
        case 'Stock Alerts':
          if (!profile.role.role.STOCK_ALERTS) {
            menuItems[key].active = false;
          }
          break;
        case 'Reports':
          if (!profile.role.role.REPORTS) {
            menuItems[key].active = false;
          }
          break;
        case 'Support':
          if (!profile.role.role.SUPPORT) {
            menuItems[key].active = false;
          }
          break;
        default:
          break;
      }
    }
    /* let totalunread = 0;
    if (Object.keys(eventsnotif).length) {
      if (Object.keys(eventsnotif.attributes).length) {
        totalunread = eventsnotif.attributes.total_new_event;
      }
    } */

    return (
      <div>
        <ul className={classes.ul}>
          {menuItems.map(item => (
            <li className={classes.li} key={item.label}>
              {item.active ?
                <NavLink
                  className={classNames(
                      classes.navlink,
                      menu === item.label ?
                      classes.menuactive
                      : '',
                      )}
                  to={item.url}
                  exact
                  onClick={this.close}
                >
                  {item.icon}
                  <Typography variant="overline">
                    {item.label}
                  </Typography>
                </NavLink>
              :
                <a
                  className={classNames(classes.navlink, classes.menuinactive)}
                  href={false}
                >
                  {item.icon}
                  <Typography variant="overline">
                    {item.label}
                  </Typography>
                </a>
              }
            </li>
          ))}
          {/* <li className={classNames(classes.li, classes.holiday)}>
            {profile.role.role.CALENDAR ?
              <NavLink className={classes.navlink} to="/holidays" exact onClick={this.clearEvent} >
                <Holiday />
                <Typography variant="overline">
                  Calendar
                </Typography>
                <Badge
                  color="secondary"
                  badgeContent={totalunread}
                  invisible={totalunread === 0}
                  className={`${classes.calendarbadge} ${totalunread !== 0 ? 'darker' : ''}`}
                >
                  &nbsp;
                </Badge>
              </NavLink>
            :
              <a
                className={classNames(classes.navlink, classes.menuinactive)}
                href={false}
              >
                <Holiday />
                <Typography variant="overline">Calendar</Typography>
              </a>
            }
          </li> */}
          <li className={classNames(classes.li, classes.faq)}>
            {profile.role.role.MANAGE_ACCOUNTS ?
              <NavLink
                className={classNames(
                  classes.navlink,
                  menu === 'Settings' ?
                  classes.menuactive
                  : '',
                )}
                to="/notification"
                exact
                onClick={this.close}
              >
                <SettingsIcon />
                <Typography variant="overline">
                  Settings
                </Typography>
              </NavLink>
            :
              <a
                className={classNames(classes.navlink, classes.menuinactive)}
                href={false}
              >
                <SettingsIcon />
                <Typography variant="overline">Settings</Typography>
              </a>
            }
          </li>
          <li className={classNames(classes.li, classes.powered)}>
            <div>
              <Typography>
                Powered by
              </Typography>
              <div>
                <img className={classes.logo} src={iPSLogo} alt="iPS" />
              </div>
            </div>
          </li>
          {/* <li className={classNames(classes.li, classes.faq)}>
            <Typography variant="overline" onClick={this.testFetch}>
              Testing
            </Typography>
          </li> */}
        </ul>
      </div>
    );
  }
}

LeftMenu.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  menuItems: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  profile: PropTypes.instanceOf(Object).isRequired,
  actions: PropTypes.instanceOf(Object), // eslint-disable-line
  calendarEventsNotif: PropTypes.func, // eslint-disable-line
  eventsnotif: PropTypes.instanceOf(Object), // eslint-disable-line
  resetCalendarEventsNotif: PropTypes.func.isRequired,
  /* testApi: PropTypes.func.isRequired, */
};

export default withRouter(withStyles(styles)(LeftMenu));
