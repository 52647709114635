import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Typography } from '@material-ui/core';
import { ReactComponent as Plus } from 'resources/icons/plus.svg';
import styles from './TopBarStyle';

class Addbtn extends Component {
  showModal = (e) => {
    e.stopPropagation();
    const { actions, page, location } = this.props;
    let modalKey = '';
    let params = {};
    switch (page) {
      case 'orders':
        params = { type: 'add', action: 'add' };
        /* if (location.pathname === '/shipped-orders') {
          params = { type: 'create' };
        } */
        modalKey = 'modal-order';
        actions.ui.openModal({ modalKey, params });
        break;
      case 'products':
        params = { type: 'add', action: 'add', source: 'listing' };
        if (location.pathname !== '/products-vendor-orders') {
          params = { type: 'add product', action: 'add', source: 'listing' };
        }
        modalKey = 'modal-products';
        actions.ui.openModal({ modalKey, params });
        break;
      case 'support':
        if (location.pathname === '/special-projects') {
          actions.ui.openPage({ params: { el: 'SpecialProjects', id: 0 } });
        } else {
          let id;
          const loc = location.pathname.split('/', 4, 0);
          switch (loc[1]) {
            case 'support':
              id = 1;
              break;
            case 'billing-support':
              id = 2;
              break;
            case 'tech-support':
              id = 3;
              break;
            default:
              break;
          }
          actions.ui.openPage({ params: { el: 'SupportForm', id } });
        }
        actions.ui.closeDrawer();
        break;
      case 'supply-receipts':
        break;
      default:
        break;
    }
  };

  render() {
    const { classes, searchType/* , location */ } = this.props;
    const addTitle = 'Create';
    /* switch (location.pathname) {
      case '/returned-orders':
      case '/return-authorization':
        addTitle = 'Create RA';
        break;
      default:
        break;
    } */

    let stype = '';
    if (typeof searchType !== 'undefined') {
      stype = searchType;
    }

    return (
      <div className={`${classes.addbtn} ${stype}btn`} onClick={this.showModal}>
        <IconButton className={classes.iconbtn} aria-label="Add">
          <Plus
            className={classes.icon}
          />
        </IconButton>
        <Typography className={`${classes.addtitle} ${stype}`} component="span">
          {addTitle}
        </Typography>
      </div>
    );
  }
}

Addbtn.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  actions: PropTypes.instanceOf(Object).isRequired,
  page: PropTypes.string.isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  searchType: PropTypes.string, // eslint-disable-line
};

export default withStyles(styles)(Addbtn);
