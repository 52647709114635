const styles = theme => ({
  reviewcontainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width:1240px)': {
      display: 'block',
    },
  },
  boxA: {
    width: 536,
    '@media (max-width:1240px)': {
      width: '100%',
    },
  },
  boxB: {
    width: 532,
    '@media (max-width:1240px)': {
      width: '100%',
    },
  },
  leftcontainer: {
    '& .rinfo': {
      padding: '26px 0 14px',
    },
    '& .rinfo label, & .profile label': {
      color: theme.palette.primary.light,
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '14px',
      marginBottom: 6,
    },
    '& .rinfo strong': {
      color: theme.palette.common.black,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '19px',
      marginBottom: 12,
    },
    '& .rinfo div': {
      position: 'relative',
      marginBottom: 12,
      '&.divgroup': {
        marginBottom: 18,
      },
    },
    '& .rinfo span': {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '21px',
      color: theme.palette.text.primary,
      paddingLeft: 22,
    },
    '& .rinfo svg': {
      position: 'absolute',
      top: 3,
      left: 0,
      fill: '#949DB2',
    },
    '& .profile': {
      padding: '44px 0 22px',
      borderTop: '1px solid #E8EAEE',
    },
    '& .profile .list': {
      display: 'block',
    },
    '& .profile .list > div': {
      backgroundColor: theme.palette.common.white,
      padding: '18px 0',
      borderRadius: 5,
    },
    '& .prinfo div': {
      marginBottom: 35,
    },
    '& .prinfo .plbl': {
      color: theme.palette.text.primary,
    },
    '& .more': {
      display: 'none',
    },
    '@media (max-width:1240px)': {
      '& .rinfo': {
        padding: 0,
        marginBottom: 18,
      },
      '& .profile .list > div': {
        backgroundColor: 'transparent',
        padding: '18px 0 0 0',
      },
      '&.bgwhite': {
        backgroundColor: theme.palette.common.white,
        borderBottom: '1px solid #E8EAEE',
      },
      '& .rinfocontainer': {
        padding: '18px 30px 0',
      },
      '& .rinfocontainer[data-expand="false"]': {
        '&.rpi': {
          padding: '22px 30px 26px',
        },
      },
      '& .rinfocontainer[data-expand="false"] .rinfo div': {
        marginBottom: 18,
      },
      '& .rinfocontainer[data-expand="false"] .expansionPanel': {
        position: 'relative',
      },
      '& .rinfocontainer[data-expand="false"] .expansionDetails': {
        display: 'none',
      },
      '& .more': {
        position: 'absolute',
        top: '5px',
        right: 30,
        left: 'inherit',
        display: 'block',
      },
      '& .rinfo svg.more': {
        top: '5px !important',
        right: '0 !important',
        left: 'inherit !important',
        display: 'block',
      },
    },
  },
  rightcontainer: {
    backgroundColor: theme.palette.background.default,
    position: 'absolute',
    right: 40,
    width: 532,
    height: 'calc(100% - 34px)',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    padding: '46px 32px',
    border: `1px solid ${theme.palette.background.border}`,
    borderBottom: '0 none',
    '&.rpi': {
      padding: '28px 30px',
    },
    '& .spacing': {
      width: '100%',
      height: 22,
      margin: 0,
    },
    '@media (max-width:1240px)': {
      position: 'relative',
      top: 0,
      left: 0,
      width: '100%',
      height: 'auto',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      padding: '30px 30px 50px',
      border: '0 none',
      '&.mainright': {
        padding: '30px 0 50px',
      },
    },
  },
  rrwrapper: {
    marginBottom: 30,
  },
  rrwrapperbottom: {
    marginBottom: 30,
  },
  rrcontainer: {
    position: 'relative',
    margin: '28px 0 2px 0',
    '&.rr1': {
      margin: '40px 0 0 0',
    },
    '&.rr2': {
      margin: '4px 0 40px 0',
    },
    '& .itr': {
      position: 'absolute',
      left: 0,
      bottom: 5,
    },
    '& .itr .err': {
      color: 'red',
      marginLeft: 12,
      fontSize: 12,
    },
    '@media (max-width:1240px)': {
      margin: 0,
      flexDirection: 'column',
      '& :nth-child(1)': {
        order: 2,
      },
      '& :nth-child(2)': {
        order: 1,
      },
    },
  },
  mbt40: {
    marginBottom: 40,
    '@media (max-width:1240px)': {
      marginBottom: 16,
    },
  },
  notescontainer: {
    '& .txtwrapper': {
      paddingLeft: 74,
    },
    '@media (max-width:1240px)': {
      '& .leftbox': {
        display: 'none',
      },
      '& .rightbox': {
        maxWidth: '100%',
        flexBasis: '100%',
      },
      '& .txtwrapper': {
        padding: '0 30px',
      },
    },
  },
  rarow: {
    '& .tcqty div': {
      marginBottom: 0,
      width: 70,
      height: 30,
      lineHeight: '30px',
      padding: 0,
      textAlign: 'left',
    },
    '& .tcqty select': {
      height: 30,
    },
    '& .tcqty div div div div': {
      padding: '0 12px',
    },
    '& .ritem label': {
      margin: 0,
    },
    '& .selectHolder': {
      marginBottom: 0,
    },
    '& td': {
      padding: '4px 38px 4px 24px',
      '@media (max-width:1240px)': {
        padding: 0,
      },
    },
  },
  rahrow: {
    '& th.desc': {
      width: '32%',
      textAlign: 'left',
    },
  },
  lastItem: {
    padding: '0 0 18px 0',
  },
  sizelbl: {
    fontSize: 14,
    color: theme.palette.text.primary,
    margin: '26px 0 10px',
    width: '100%',
    '@media (max-width:1240px)': {
      paddingLeft: 30,
    },
  },
  ptbl: {
    '& th': {
      padding: '4px 18px',
    },
    '@media (max-width:1240px)': {
      display: 'none',
    },
  },
  capital: {
    textTransform: 'capitalize',
  },
  maingridcontainer: {
    padding: 0,
  },
  gridcontainer2: {
    padding: '26px 0',
    '@media (max-width:1240px)': {
      padding: '16px 0',
    },
  },
  gridcontainer3: {
    padding: '26px 0',
    '@media (max-width:1240px)': {
      padding: '16px 30px',
    },
  },
  gridTable: {
    display: 'none',
    '@media (max-width:1240px)': {
      backgroundColor: '#C5D1E0',
      display: 'block',
      padding: '26px 0',
    },
    '& span': {
      color: '#767F91',
      marginBottom: 7,
    },
    '& p': {
      color: '#333',
      fontWeight: 400,
    },
  },
  tlabel2: {
    '@media (max-width:1240px)': {
      backgroundColor: '#C5D1E0',
      margin: 0,
      padding: '42px 26px 0',
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '19px',
      color: theme.palette.primary.main,
    },
  },
  div100: {
    '@media (max-width:1240px)': {
      maxWidth: '100%',
      flexBasis: '100%',
      padding: '0 30px 0',
      '&.divcolb': {
        paddingBottom: 16,
      },
      '&.divcolbb': {
        paddingBottom: 16,
        borderBottom: '1px solid #E8EAEE',
      },
      '&.divcolt': {
        paddingTop: 16,
      },
    },
  },
  div100bottom: {
    '@media (max-width:1240px)': {
      maxWidth: '100%',
      flexBasis: '100%',
      padding: '16px 30px 0',
      borderTop: '1px solid #E8EAEE',
    },
  },
  div50: {
    '@media (max-width:1240px)': {
      padding: 0,
      maxWidth: '50%',
      flexBasis: '50%',
      '&.divcolbb': {
        paddingBottom: 16,
        borderBottom: '1px solid #E8EAEE',
      },
      '&.divcolt': {
        paddingTop: 16,
      },
    },
  },
  div33: {
    '@media (max-width:1240px)': {
      padding: 0,
      maxWidth: '33.33%',
      flexBasis: '33.33%',
      '&.div33left': {
        padding: '0 0 0 30px',
      },
      '&.div33center': {
        padding: '0 15px',
      },
      '&.div33right': {
        padding: '0 30px 0 0',
      },
    },
  },
  div40: {
    '@media (max-width:1240px)': {
      padding: 0,
      maxWidth: '38%',
      flexBasis: '38%',
    },
  },
  div20: {
    '@media (max-width:1240px)': {
      padding: 0,
      maxWidth: '24%',
      flexBasis: '24%',
    },
  },
  tblleft: {
    '@media (max-width:1240px)': {
      padding: '0 0 0 26px',
    },
  },
  tblcenter: {
    '@media (max-width:1240px)': {
      padding: '0 6px',
    },
  },
  tblright: {
    '@media (max-width:1240px)': {
      padding: '0 26px 0 0',
    },
  },
  divleft: {
    '@media (max-width:1240px)': {
      padding: '0 0 0 30px',
    },
  },
  divright: {
    '@media (max-width:1240px)': {
      padding: '0 30px 0 20px',
    },
  },
  divbottomleft: {
    '@media (max-width:1240px)': {
      padding: '0 0 16px 30px',
    },
  },
  divbottomright: {
    '@media (max-width:1240px)': {
      padding: '0 30px 16px 20px',
    },
  },
  divider: {
    width: '100%',
    '@media (max-width:1240px)': {
      backgroundColor: '#E8EAEE',
      '&.po': {
        display: 'none',
      },
    },
  },
  hideonmobile: {
    '@media (max-width:1240px)': {
      display: 'none',
    },
  },
  dividermobile: {
    display: 'none',
    '@media (max-width:1240px)': {
      display: 'block',
    },
  },
  itemreturnbox: {
    '@media (max-width:1240px)': {
      backgroundColor: theme.palette.background.default,
      maxWidth: '100%',
      flexBasis: '100%',
      padding: '0 0 18px 0',
      '& > span': {
        position: 'relative !important',
        bottom: '0 !important',
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '22px',
        color: theme.palette.common.black,
      },
    },
  },
  reasonbox: {
    '@media (max-width:1240px)': {
      maxWidth: '100%',
      flexBasis: '100%',
      padding: '0 30px 10px',
    },
  },
  fordesktop: {
    display: 'block',
    '@media (max-width:1240px)': {
      display: 'none',
    },
  },
  formobile: {
    display: 'none',
    '@media (max-width:1240px)': {
      display: 'block',
    },
  },
  fordesktopflex: {
    display: 'flex',
    '@media (max-width:1240px)': {
      display: 'none',
    },
  },
  formobileflex: {
    display: 'none',
    '@media (max-width:1240px)': {
      display: 'flex',
    },
  },
  details: {
    '& h2': {
      fontSize: 14,
      color: '#1F1C1C',
      margin: '26px 0 0 0',
    },
    '@media (max-width:1240px)': {
      backgroundColor: theme.palette.background.default,
      '&.po h2': {
        padding: '16px 30px 0',
      },
      '& .oinfotop': {
        position: 'relative',
      },
      '&[data-expand="false"] .oinfo': {
        display: 'none',
      },
    },
  },
  sizedeliverlbl: {
    fontSize: 14,
    color: theme.palette.common.black,
    fontWeight: 300,
    lineHeight: '16px',
  },
  sidea: {
    paddingRight: 9,
    '@media (max-width:1240px)': {
      maxWidth: '100%',
      flexBasis: '100%',
      padding: '12px 30px 0px 30px',
      '& > span': {
        position: 'relative !important',
        bottom: '0 !important',
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '22px',
        color: theme.palette.common.black,
      },
    },
  },
  sideb: {
    paddingLeft: 9,
    '@media (max-width:1240px)': {
      maxWidth: '100%',
      flexBasis: '100%',
      padding: '12px 30px 0px 30px',
      '& > span': {
        position: 'relative !important',
        bottom: '0 !important',
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '22px',
        color: theme.palette.common.black,
      },
    },
  },
  breaktxt: {
    wordBreak: 'break-all',
    paddingRight: 10,
  },
});

export default styles;
