import React, { Component, Fragment } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Menu, MenuItem, Typography } from '@material-ui/core';
import { ReactComponent as ArrowDownSolid } from 'resources/icons/arrow-down-solid.svg';
import styles from './MobileMenuSelectorStyle';

export const dashboardItems = (profile) => {
  const items = [
    { label: `Welcome ${profile.customer.created_by_username}!`, route: '', url: '/' },
  ];
  return items;
};

export const salesOrdersItem = (role) => {
  let items = [];
  if (role.role.SALES_ORDERS) {
    items = [
      { label: 'All Orders', route: 'orders', url: '/orders' },
      { label: 'Pending Orders', route: 'pending-orders', url: '/pending-orders' },
      { label: 'Shipped Orders', route: 'shipped-orders', url: '/shipped-orders' },
      { label: 'Returned Orders', route: 'returned-orders', url: '/returned-orders' },
      { label: 'RA', route: 'return-authorization', url: '/return-authorization' },
      { label: '3rd Party', route: 'third-party', url: '/third-party' },
    ];
  }
  return items;
};

export const orderItems = (role) => {
  let items = [];
  if (role.role.PURCHASE_ORDERS) {
    items = [
      { label: 'Purchase Orders', route: 'products-vendor-orders', url: '/products-vendor-orders' },
    ];
  }
  return items;
};

export const supplyReceiptsItems = (role) => {
  let items = [];
  if (role.role.SUPPLY_RECEIPTS) {
    items = [
      { label: 'Supply Receipts', route: 'supply-receipts', url: '/supply-receipts' },
    ];
  }
  return items;
};

export const productItems = (role) => {
  let items = [];
  if (role.role.PRODUCTS) {
    items = [
      { label: 'All Products', route: 'products', url: '/products' },
      { label: 'Top Selling', route: 'top-selling-products', url: '/top-selling-products' },
      { label: 'Low Stock', route: 'products-low-in-stocks', url: '/products-low-in-stocks' },
      { label: 'Out of Stock', route: 'products-out-of-stocks', url: '/products-out-of-stocks' },
    ];
  }
  return items;
};

export const stockAlertsItems = (role) => {
  let items = [];
  if (role.role.STOCK_ALERTS) {
    items = [
      { label: 'Stock Alert', route: 'stock-alert-settings', url: '/stock-alert-settings' },
    ];
  }
  return items;
};

export const reportItems = (role) => {
  let items = [];
  if (role.role.REPORTS) {
    items = [
      { label: 'Orders', route: 'reports', url: '/reports' },
      { label: 'Inventory', route: 'inventory-reports', url: '/inventory-reports' },
      { label: 'Returns', route: 'returns-reports', url: '/returns-reports' },
      { label: 'Supply Receipts', route: 'supply-receipts-reports', url: '/supply-receipts-reports' },
    ];
  }
  return items;
};

export const supportItems = (role) => {
  let items = [];
  if (role.role.SUPPORT) {
    items = [
      { label: 'Support', route: 'support', url: '/support' },
    ];
  }
  return items;
};

export const manageItems = (role) => {
  let items = [];
  if (role.role.MANAGE_ACCOUNTS) {
    items = [
      { label: 'Edit Profile', route: 'profile', url: '/profile' },
      { label: 'Account Info', route: 'account-info', url: '/account-info' },
    ];
  }
  return items;
};

export const settingItems = (role) => {
  let items = [];
  if (role.role.MANAGE_ACCOUNTS) {
    items = [
      { label: 'Notification Settings', route: 'notification', url: '/notification' },
      { label: 'Manage Roles', route: 'manage-roles', url: '/manage-roles' },
      { label: 'Manage Timezone', route: 'timezone', url: '/timezone' },
    ];
  }
  return items;
};

export const otherPages = () => {
  const items = [
    { label: 'Calendar', route: 'holidays', url: '/holidays' },
  ];
  return items;
};

class MobileMenuSelector extends Component {
  state = {
    anchorEl: null,
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { headerStatus, handleLogout } = this.props;
    if (headerStatus === 'expired') {
      handleLogout();
      return true;
    }
    const { classes } = this.props;
    const {
      location, profile,
    } = this.props;
    const loc = location.pathname.split('/', 4, 0);
    let data = [];
    let value = loc[1];
    switch (loc[1]) {
      case '':
        data = dashboardItems(profile);
        break;
      case 'orders':
        data = salesOrdersItem(profile.role);
        value = 'All Orders';
        break;
      case 'pending-orders':
        data = salesOrdersItem(profile.role);
        value = 'Pending Orders';
        break;
      case 'shipped-orders':
        data = salesOrdersItem(profile.role);
        value = 'Shipped Orders';
        break;
      case 'returned-orders':
        data = salesOrdersItem(profile.role);
        value = 'Returned Orders';
        break;
      case 'return-authorization':
        data = salesOrdersItem(profile.role);
        value = 'RA';
        break;
      case 'third-party':
        data = salesOrdersItem(profile.role);
        value = '3rd Party';
        break;
      case 'products-vendor-orders':
        data = orderItems(profile.role);
        value = 'Purchase Orders';
        break;
      case 'products':
        data = productItems(profile.role);
        value = 'All Products';
        break;
      case 'top-selling-products':
        data = productItems(profile.role);
        value = 'Top Selling';
        break;
      case 'products-low-in-stocks':
        data = productItems(profile.role);
        value = 'Low Stock';
        break;
      case 'products-out-of-stocks':
        data = productItems(profile.role);
        value = 'Out of Stock';
        break;
      case 'reports':
        data = reportItems(profile.role);
        value = 'Orders';
        break;
      case 'inventory-reports':
        data = reportItems(profile.role);
        value = 'Inventory';
        break;
      case 'returns-reports':
        data = reportItems(profile.role);
        value = 'Returns';
        break;
      case 'supply-receipts-reports':
        data = reportItems(profile.role);
        value = 'Supply Receipts';
        break;
      case 'support':
        data = supportItems(profile.role);
        value = 'Support';
        break;
      /* case 'billing-support':
        data = supportItems(profile.role);
        value = 'Billing Support';
        break;
      case 'tech-support':
        data = supportItems(profile.role);
        value = 'Tech Support';
        break; */
      case 'profile':
        data = manageItems(profile.role);
        value = 'Edit Profile';
        break;
      case 'account-info':
        data = manageItems(profile.role);
        value = 'Account Info';
        break;
      /* case 'staff':
        data = manageItems(profile.role);
        value = 'Account Staff';
        break; */
      case 'manage-roles':
        data = settingItems(profile.role);
        value = 'Manage Roles';
        break;
      case 'notification':
        data = settingItems(profile.role);
        value = 'Notifications Settings';
        break;
      case 'timezone':
        data = settingItems(profile.role);
        value = 'Manage Timezone';
        break;
      case 'holidays':
        data = otherPages();
        break;
      default:
        break;
    }
    const items = data;
    value = value.replace('-', ' ');
    const { anchorEl } = this.state;
    return (
      <Fragment>
        { items.length > 1 ?
          <Button
            className={classes.menu}
            aria-owns={anchorEl ? 'simple-menu' : undefined}
            aria-haspopup="true"
            onClick={this.handleClick}
          >
            {value} <ArrowDownSolid />
          </Button>
        :
          <Typography className={classes.htitle}>{value}</Typography>
        }
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {items.map(item => (
            <MenuItem
              key={item.label}
              component={NavLink}
              to={item.url}
              onClick={this.handleClose}
              selected={value.toLowerCase() === item.label.toLowerCase()}
            >
              {item.label}
            </MenuItem>
          ))}
        </Menu>
      </Fragment>
    );
  }
}

MobileMenuSelector.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  profile: PropTypes.instanceOf(Object), // eslint-disable-line
  headerStatus: PropTypes.string, // eslint-disable-line
  handleLogout: PropTypes.func, // eslint-disable-line
};

export default withRouter(withStyles(styles)(MobileMenuSelector));
