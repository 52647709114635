import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
/* import { ReactComponent as Filter } from 'resources/icons/filter.svg'; */
import { Typography, Chip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './FilterStyle';

class FilterDisplay extends Component {
  handleDelete = (e, val) => {
    const {
      filtersdata, setfilters, setloadtype, extradata, setExtraData,
    } = this.props;

    const filterItems = filtersdata
      .filter(data => data.id !== val)
      .map(dfilter => (dfilter));
    setfilters(filterItems);

    if (val === 'date') {
      if (typeof extradata !== 'undefined') {
        const extraItems = extradata
          .filter(data => data.id !== 'datestart' && data.id !== 'dateend')
          .map(dfilter => (dfilter));
        setExtraData(extraItems);
      }
    }

    setloadtype('filter updated');
  }
  render() {
    const { classes, filtersdata, dispstyle } = this.props;
    return (
      <div className={`filters ${classes.fdisp} ${typeof filtersdata !== 'undefined' && filtersdata.length ? classes.fdisp2 : ''} ${typeof dispstyle !== 'undefined' ? dispstyle : ''}`}>
        { typeof filtersdata !== 'undefined' && filtersdata !== null && filtersdata.length ?
          <Fragment>
            <Typography className={classes.filterlbl} component="label">Filters: </Typography>
            { filtersdata.map((filter) => {
              let orderDateStart;
              let orderDateEnd;
              if (typeof filter.order_date_start !== 'undefined' && filter.order_date_start !== '') {
                const ods = filter.order_date_start.split('-');
                orderDateStart = `${ods[1]}-${ods[2]}-${ods[0]}`;
              }
              if (typeof filter.order_date_end !== 'undefined' && filter.order_date_end !== '') {
                const ode = filter.order_date_end.split('-');
                orderDateEnd = `${ode[1]}-${ode[2]}-${ode[0]}`;
              }
              let _dateStart;
              let _dateEnd;
              if (typeof filter.date_start !== 'undefined' && filter.date_start !== '') {
                const ods = filter.date_start.split('-');
                _dateStart = `${ods[1]}-${ods[2]}-${ods[0]}`;
              }
              if (typeof filter.date_end !== 'undefined' && filter.date_end !== '') {
                const ode = filter.date_end.split('-');
                _dateEnd = `${ode[1]}-${ode[2]}-${ode[0]}`;
              }
              return (
                <div className={classes.fwrapper} key={filter.id}>
                  { typeof filter.vendor_name !== 'undefined' && filter.vendor_name !== '' ?
                    <Chip
                      label={
                        <Typography component="label">
                          VENDOR: <span>{filter.vendor_name}</span>
                        </Typography>
                      }
                      onDelete={e => this.handleDelete(e, filter.id)}
                      className={classes.chip}
                    />
                  : null }
                  {/* { typeof filter.date !== 'undefined' && filter.date !== '' ?
                    <Chip
                      label={
                        <Typography component="label">
                          VENDOR: <span>{filter.vendor}</span>
                        </Typography>
                      }
                      onDelete={e => this.handleDelete(e, filter.id)}
                      className={classes.chip}
                    />
                  : null } */}
                  { typeof filter.date_start !== 'undefined' && filter.date_start !== '' ?
                    <Chip
                      label={
                        <>
                          <Typography component="label">
                            From: <span>{_dateStart}</span>
                          </Typography>
                          &nbsp;&nbsp;&nbsp;
                          <Typography component="label">
                            To: <span>{_dateEnd}</span>
                          </Typography>
                        </>
                      }
                      onDelete={e => this.handleDelete(e, filter.id)}
                      className={classes.chip}
                    />
                  : null }
                  { typeof filter.order_date_start !== 'undefined' && filter.order_date_start !== '' ?
                    <Chip
                      label={
                        <Typography component="label">
                          From: <span>{orderDateStart}</span>
                        </Typography>
                      }
                      onDelete={e => this.handleDelete(e, filter.id)}
                      className={classes.chip}
                    />
                  : null }
                  { typeof filter.order_date_end !== 'undefined' && filter.order_date_end !== '' ?
                    <Chip
                      label={
                        <Typography component="label">
                          To: <span>{orderDateEnd}</span>
                        </Typography>
                      }
                      onDelete={e => this.handleDelete(e, filter.id)}
                      className={classes.chip}
                    />
                  : null }
                  { typeof filter.recipient_name !== 'undefined' && filter.recipient_name !== '' ?
                    <Chip
                      label={
                        <Typography component="label">
                          RECIPIENT: <span>{filter.recipient_name}</span>
                        </Typography>
                      }
                      onDelete={e => this.handleDelete(e, filter.id)}
                      className={classes.chip}
                    />
                  : null }
                  { typeof filter.company_name !== 'undefined' && filter.company_name !== '' ?
                    <Chip
                      label={
                        <Typography component="label">
                          COMPANY: <span>{filter.company_name}</span>
                        </Typography>
                      }
                      onDelete={e => this.handleDelete(e, filter.id)}
                      className={classes.chip}
                    />
                  : null }
                  { typeof filter.recipient_email !== 'undefined' && filter.recipient_email !== '' ?
                    <Chip
                      label={
                        <Typography component="label">
                          EMAIL: <span>{filter.recipient_email}</span>
                        </Typography>
                      }
                      onDelete={e => this.handleDelete(e, filter.id)}
                      className={classes.chip}
                    />
                  : null }
                  { typeof filter.status !== 'undefined' && filter.status !== '' ?
                    <Chip
                      label={
                        <Typography component="label">
                          STATUS: <span>{filter.status}</span>
                        </Typography>
                      }
                      onDelete={e => this.handleDelete(e, filter.id)}
                      className={classes.chip}
                    />
                  : null }
                  { typeof filter.carrier_code !== 'undefined' && filter.carrier_code !== '' ?
                    <Chip
                      label={
                        <Typography component="label">
                          CARRIER: <span>{filter.carrier_code}</span>
                        </Typography>
                      }
                      onDelete={e => this.handleDelete(e, filter.id)}
                      className={classes.chip}
                    />
                  : null }
                  { typeof filter.vendor !== 'undefined' && filter.vendor !== '' ?
                    <Chip
                      label={
                        <Typography component="label">
                          VENDOR: <span>{filter.vendor}</span>
                        </Typography>
                      }
                      onDelete={e => this.handleDelete(e, filter.id)}
                      className={classes.chip}
                    />
                  : null }
                  { typeof filter.sku !== 'undefined' && filter.sku !== '' ?
                    <Chip
                      label={
                        <Typography component="label">
                          SKU: <span>{filter.sku}</span>
                        </Typography>
                      }
                      onDelete={e => this.handleDelete(e, filter.id)}
                      className={classes.chip}
                    />
                  : null }
                  { typeof filter.order_no !== 'undefined' && filter.order_no !== '' ?
                    <Chip
                      label={
                        <Typography component="label">
                          Order Number: <span>{filter.order_no}</span>
                        </Typography>
                      }
                      onDelete={e => this.handleDelete(e, filter.id)}
                      className={classes.chip}
                    />
                  : null }
                  { typeof filter.recipient !== 'undefined' && filter.recipient !== '' ?
                    <Chip
                      label={
                        <Typography component="label">
                          Recipient Name: <span>{filter.recipient}</span>
                        </Typography>
                      }
                      onDelete={e => this.handleDelete(e, filter.id)}
                      className={classes.chip}
                    />
                  : null }
                  { typeof filter.po_no !== 'undefined' && filter.po_no !== '' ?
                    <Chip
                      label={
                        <Typography component="label">
                          PO Number: <span>{filter.po_no}</span>
                        </Typography>
                      }
                      onDelete={e => this.handleDelete(e, filter.id)}
                      className={classes.chip}
                    />
                  : null }
                  { typeof filter.ra_number !== 'undefined' && filter.ra_number !== '' ?
                    <Chip
                      label={
                        <Typography component="label">
                          RA Number: <span>{filter.ra_number}</span>
                        </Typography>
                      }
                      onDelete={e => this.handleDelete(e, filter.id)}
                      className={classes.chip}
                    />
                  : null }
                  { typeof filter.email !== 'undefined' && filter.email !== '' ?
                    <Chip
                      label={
                        <Typography component="label">
                          Email: <span>{filter.email}</span>
                        </Typography>
                      }
                      onDelete={e => this.handleDelete(e, filter.id)}
                      className={classes.chip}
                    />
                  : null }
                  { typeof filter.description !== 'undefined' && filter.description !== '' ?
                    <Chip
                      label={
                        <Typography component="label">
                          Description: <span>{filter.description}</span>
                        </Typography>
                      }
                      onDelete={e => this.handleDelete(e, filter.id)}
                      className={classes.chip}
                    />
                  : null }
                  { typeof filter.tracking_number !== 'undefined' && filter.tracking_number !== '' ?
                    <Chip
                      label={
                        <Typography component="label">
                          Tracking Number: <span>{filter.tracking_number}</span>
                        </Typography>
                      }
                      onDelete={e => this.handleDelete(e, filter.id)}
                      className={classes.chip}
                    />
                  : null }
                </div>
              );
            })}
          </Fragment>
        : null }
      </div>
    );
  }
}

FilterDisplay.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  filtersdata: PropTypes.array, // eslint-disable-line
  setfilters: PropTypes.func, // eslint-disable-line
  setloadtype: PropTypes.func, // eslint-disable-line
  dispstyle: PropTypes.string, // eslint-disable-line
  setExtraData: PropTypes.func, // eslint-disable-line
  extradata: PropTypes.instanceOf(Array), // eslint-disable-line
};

export default withStyles(styles)(FilterDisplay);
