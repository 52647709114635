const styles = theme => ({
  ...theme.classes,
  root: {
    width: '100%',
    padding: '0 6px',
    marginTop: -38,
    '& .ulfilterlist': {
      backgroundColor: '#fff',
      listStyleType: 'none',
      margin: 0,
      padding: 0,
      display: 'flex',
      width: '100%',
      height: 62,
      paddingBottom: 8,
      marginTop: 8,
      boxShadow: '0 0 6px rgba(68, 107, 176, 0.44)',
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
      '& li.lifilterlist': {
        height: 54,
        display: 'inline-flex',
        width: 130,
        borderRight: '1px solid #E4E5E7',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      '& li.lifilterlist.transdate': {
        width: 162,
      },
      '& li.lifilterlist:nth-child(1)': {
        width: 274,
      },
      '& li.lifilterlist:last-child': {
        borderRight: 0,
        flexGrow: 5,
      },
      '& li.lifilterlist span.lbl': {
        fontSize: 12,
        color: theme.palette.primary.light,
        display: 'inline-block',
        '&.lblsort': {
          marginLeft: 8,
        },
      },
      '& li.lifilterlist div.sbox': {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        padding: '0 18px',
      },
      '& li.lifilterlist div.btn': {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        width: '100%',
        height: '100%',
        /* padding: '0 18px', */
      },
      '& li.lifilterlist div.status-btn': {
        position: 'relative',
        zIndex: 4,
        padding: 0,
      },
      '& li.lifilterlist div.sdiv': {
        display: 'inline-flex',
        alignItems: 'center',
        padding: '0 4px',
        height: '100%',
      },
      '& li.lifilterlist .btn svg': {
        fill: theme.palette.primary.light,
      },
    },
    '@media (max-width:1240px)': {
      display: 'none',
    },
  },
  add: {
    display: 'inline-block',
    marginRight: 24,
    /* '& svg': {
      stroke: theme.palette.secondary.main,
    }, */
  },
  updatebtn: {
    position: 'absolute',
    top: 76,
    /* right: 48, */
    right: 18,
    display: 'flex',
    width: 208,
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .btndesktop': {
      display: 'none',
    },
    '& p': {
      display: 'inline-block',
    },
    '& .syncbtn': {
      cursor: 'pointer',
      width: 20,
      height: 20,
    },
  },
});

export default styles;
